import { differenceInCalendarDays, parseISO, format } from 'date-fns';

export function formatExpiresAt(expiresAt: string): string {
    const expirationDate = parseISO(expiresAt);
    const today = new Date();
    
    const diffDays = differenceInCalendarDays(expirationDate, today);
    
    const formattedDate = format(expirationDate, 'dd/MM/yyyy');
    
    return `${diffDays} dia${diffDays !== 1 ? 's' : ''} (${formattedDate})`;
}