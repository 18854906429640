import { ReactNode, createContext, useState } from "react";
import { useQuery } from "react-query";
import axiosClient from "../libs/axios";
import { Typography } from "@mui/material";

export interface CompanyContextType {
    identifier: string | null
    name: string | null
    logo: string | null
    cover: string | null
    status: string | null
    plan: string | null
    expire_at: string | null
    updateLogo: (newLogo: string) => void
    updateCover: (newCover: string) => void
}

export const CompanyContext = createContext<CompanyContextType>({
    identifier: null,
    name: null,
    logo: null,
    cover: null,
    status: null,
    plan: null,
    expire_at: null,
    updateLogo: () => {},
    updateCover: () => {}
});

export function CompanyProvider({ children }: { children: ReactNode }) {
    const [logo, setLogo] = useState<string | null>(null)
    const [cover, setCover] = useState<string| null>(null)
    const [status, setStatus] = useState<string | null>(null)   
    const [plan, setPlan] = useState<string | null>(null)
    const [expire_at, setExpireAt] = useState<string | null>(null)

    const subdomain = window.location.hostname.split(".")[0];

    const { data: info, isFetching, refetch } = useQuery({
        queryFn: () => axiosClient.get("/public/check-company?identifier=" + subdomain), 
        cacheTime: 0,
        onSuccess: (data) => {
            setLogo(data?.data.logo)
            setCover(data?.data.cover)
            setStatus(data?.data.status)
            setPlan(data?.data.plan)
            setExpireAt(data?.data.expire_at)
        }
    });

    const updateLogo = async (newLogo: string) => {
        setLogo(newLogo)
        await refetch()
    }

    const updateCover = async (newCover: string) => {
        setCover(newCover)
        await refetch()
    }

    if(isFetching){
        return <Typography>Carregando ..</Typography>
    }

    return (
        <CompanyContext.Provider value={{...info?.data, logo, updateLogo, cover, updateCover, status, plan, expire_at}}>
            {children}
        </CompanyContext.Provider>
    )
}