import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { DynamicFormComponentProps } from "..";
import SearchIcon from '@mui/icons-material/Search';

type AddressComponentProps = {
    component: DynamicFormComponentProps;
    values: any;
    setValues: React.Dispatch<React.SetStateAction<any>>;
  };
  
export function AddressComponent({ component, values, setValues }: AddressComponentProps) {
    const addressData = values[component.id] || {
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
    };
  
    const updateField = (field: string, newValue: string) => {
      setValues({
        ...values,
        [component.id]: {
          ...addressData,
          [field]: newValue,
        },
      });
    };
  
    const handleBlurCep = async () => {
      const rawCep = addressData.cep.replace(/\D/g, '');
      if (rawCep.length === 8) {
        try {
          const response = await fetch(`https://viacep.com.br/ws/${rawCep}/json/`);
          const data = await response.json();
  
          if (!data.erro) {
            setValues({
              ...values,
              [component.id]: {
                cep: maskCep(rawCep),
                logradouro: data.logradouro || '',
                bairro: data.bairro || '',
                cidade: data.localidade || '',
                uf: data.uf || '',
                numero: addressData.numero,
                complemento: addressData.complemento,
              },
            });
          }
        } catch (error) {
          console.error('Erro ao consultar ViaCEP:', error);
        }
      }
    };
  
    const maskCep = (cepValue: string) => {
      if (!cepValue) return '';
      return cepValue.replace(/^(\d{5})(\d{3})$/, '$1-$2');
    };
  
    return (
      <Box>
        <Typography variant="subtitle1" fontWeight="bold">{component.name || 'Endereço'}</Typography>
  
        <TextField
          label="CEP"
          required={component.isRequired}
          fullWidth
          sx={{ my: 1 }}
          value={addressData.cep}
          onChange={(e) => updateField('cep', e.target.value)}
          onBlur={handleBlurCep}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
  
        <TextField
          label="Logradouro"
          fullWidth
          sx={{ my: 1 }}
          value={addressData.logradouro}
          onChange={(e) => updateField('logradouro', e.target.value)}
        />
  
        <TextField
          label="Número"
          fullWidth
          sx={{ my: 1 }}
          value={addressData.numero}
          onChange={(e) => updateField('numero', e.target.value)}
        />
  
        <TextField
          label="Complemento"
          fullWidth
          sx={{ my: 1 }}
          value={addressData.complemento}
          onChange={(e) => updateField('complemento', e.target.value)}
        />
  
        <TextField
          label="Bairro"
          fullWidth
          sx={{ my: 1 }}
          value={addressData.bairro}
          onChange={(e) => updateField('bairro', e.target.value)}
        />
  
        <TextField
          label="Cidade"
          fullWidth
          sx={{ my: 1 }}
          value={addressData.cidade}
          onChange={(e) => updateField('cidade', e.target.value)}
        />
  
        <TextField
          label="UF"
          fullWidth
          sx={{ my: 1 }}
          value={addressData.uf}
          onChange={(e) => updateField('uf', e.target.value)}
        />
      </Box>
    );
  }
  