const chipStatusColors: Record<'Done' | 'Doing' | 'Suspended', 'default' | 'success' | 'warning'> = {
  Done: 'success',
  Doing: 'warning',
  Suspended: 'default',
};

export function situationColor(situation: string) {
  return chipStatusColors[situation as 'Doing' || 'Done' || 'Suspended']
}

