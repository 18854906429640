import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material";
import { DynamicFormComponentProps } from ".";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import { DataSourceAutocomplete } from "./DataSourceAutocomplete";
import { applyMaskToValue } from "../../utils/applyMaksToValue";
import { AddressComponent } from "./AddressComponent";

type DynamicFormRenderProps = {
    components: DynamicFormComponentProps[];
    onChange?: (values: any, errors: any) => void;
    defaultValues?: any;
};

// Adiciona função de validação
function validateFields(components: DynamicFormComponentProps[], values: any): Record<string, string | Record<string, any>> {
    const errors: Record<string, string | Record<string, any>> = {};
    
    components.forEach(component => {
        const value = values?.[component.id];
        
        if (component.type === 'group' && component.components) {
            // Valida campos dentro do grupo
            const groupErrors = validateFields(component.components, value || {});
            
            // Se houver erros nos campos do grupo, adiciona ao objeto de erros
            if (Object.keys(groupErrors).length > 0) {
                errors[component.id] = groupErrors;
            } else if (component.isRequired && (!value || Object.keys(value).length === 0)) {
                errors[component.id] = 'Campo obrigatório';
            }
        } else if (component.isRequired) {
            if (!value || (Array.isArray(value) && value.length === 0)) {
                errors[component.id] = 'Campo obrigatório';
            }
        }
    });
    
    return errors;
}

export function DynamicFormRender({ components, onChange, defaultValues = {} }: DynamicFormRenderProps) {

    const [values, setValues] = useState<any>(defaultValues);

    // Atualiza a função useEffect para incluir validação
    useEffect(() => {
        const newErrors = validateFields(components, values);
        onChange && onChange(values, newErrors);
    }, [values, components]);

    return (
        <Box display="flex" flexDirection="column">
            {components.map((component: DynamicFormComponentProps) => (
                <Box key={component.id} sx={{ marginBottom: 2 }}>
                    {
                        getComponentRenderer(component, values, setValues)
                    }
                </Box>
            ))}
        </Box>
    );
}

function getComponentRenderer(component: DynamicFormComponentProps, values: any, setValues: React.Dispatch<React.SetStateAction<any>>): JSX.Element {

    switch (component.type) {
        case 'text':
            return <TextField
                label={component.name}
                onChange={(e) => {
                    let newValue = e.target.value;
                    
                    if (component.mask) {
                        newValue = applyMaskToValue(newValue, component.mask);
                    }
            
                    setValues({ ...values, [component.id]: newValue });
                }}
                value={(values && values[component.id]) || ''}
                required={component.isRequired}
                variant="outlined"
                type="text"
                fullWidth
                sx={{
                    marginTop: '6px',
                }}
            />
        case 'textarea':
            return <TextField 
                label={component.name} 
                onChange={(e) => setValues({...values, [component.id]: e.target.value})} 
                value={values && values[component.id]} 
                required={component.isRequired} 
                variant="outlined" 
                type="text" 
                fullWidth 
                multiline 
                minRows={4}
            />
        case 'number':
            return <TextField 
                label={component.name} 
                onChange={(e) => setValues({...values, [component.id]: e.target.value})} 
                value={values && values[component.id]} 
                required={component.isRequired} 
                variant="outlined" 
                type={component.type} 
                fullWidth 
            />;
        case 'date':
            return (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                    <DatePicker 
                        onChange={(value) => setValues({...values, [component.id]: value})} 
                        slotProps={{ textField: { required: component.isRequired } }} 
                        format="DD/MM/YYYY" 
                        sx={{ width: '100%' }}  
                        label={component.name} 
                    />
                </LocalizationProvider>
            );
        case 'select':
            if (component.useDataSource && component.dataSource) {
                return (
                    <DataSourceAutocomplete
                        uuid={component.dataSource}
                        label={component.name}
                        required={component.isRequired}
                        value={values?.[component.id]}
                        onChange={(newValue) => setValues({...values, [component.id]: newValue})}
                    />
                );
            }

            return (
                <FormControl fullWidth>
                    <InputLabel>{component.name} {component.isRequired && `*`}</InputLabel>
                    <Select 
                        onChange={(e) => setValues({...values, [component.id]: e.target.value})} 
                        variant="outlined" 
                        label={component.name} 
                        required={component.isRequired}
                    >
                        {
                            (component.options || []).map((i: string) => {
                                return <MenuItem value={i}>{i}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            );
        case 'radio':
            return (
                <FormControl fullWidth>
                    <Typography variant="caption" fontWeight="bold">{component.name} {component.isRequired && `*`}</Typography>
                    <RadioGroup row onChange={(e) => setValues({...values, [component.id]: e.target.value})} >
                        {
                            (component.options || []).map((i: string) => {
                                return <FormControlLabel value={i} control={<Radio />} label={i} />
                            })
                        }
                    </RadioGroup>
                </FormControl>
            );
        case 'checkbox':
            return (
                <FormControl fullWidth>
                    <Typography variant="caption" fontWeight="bold">{component.name} {component.isRequired && `*`}</Typography>
                    <FormGroup row>
                        {
                            (component.options || []).map((i: string) => {
                                return <FormControlLabel 
                                    label={i} 
                                    control={<Checkbox 
                                        name={i} 
                                        onChange={(e) => {
                                            let cloneValues = {...values};
                                            let actual = cloneValues ? (cloneValues[component.id] || []) : [];
                                            
                                            if(e.target.checked && !actual.includes(e.target.name)){
                                                actual.push(e.target.name)
                                            }

                                            if(!e.target.checked){
                                                actual.splice(actual.indexOf(e.target.name), 1)
                                            }

                                            if(actual.length > 0){
                                                setValues({...cloneValues, [component.id]: actual});
                                            } else {
                                                delete cloneValues[component.id]; 
                                                setValues({...cloneValues});
                                            }

                                        }} 
                                    />
                                } />
                            })
                        }
                    </FormGroup>
                </FormControl>
            );
        case 'upload':
            return (
                <FormControl fullWidth variant="outlined">
                    <InputLabel 
                        shrink 
                        sx={{ 
                            backgroundColor: 'white', 
                            px: 1 
                        }}
                    >
                        {component.name} {component.isRequired && `*`}
                    </InputLabel>
                    <Box
                        sx={{
                            border: '1px solid rgba(0, 0, 0, 0.23)',
                            borderRadius: 1,
                            padding: '16.5px 14px',
                            '&:hover': {
                                borderColor: 'rgba(0, 0, 0, 0.87)'
                            }
                        }}
                    >
                        <input
                            type="file"
                            onChange={(e) => {
                                const file = e.target.files?.[0];
                                setValues({...values, [component.id]: file});
                            }}
                            required={component.isRequired}
                            style={{
                                width: '100%',
                                border: 'none',
                                outline: 'none'
                            }}
                        />
                    </Box>
                </FormControl>
            );
        case 'group':
            return (
                <Box>
                    <Typography fontWeight="bold">{component.name}</Typography>
                    <Stack spacing={1} mt={1}>
                        <DynamicFormRender 
                            components={component.components || []}  
                            defaultValues={values?.[component.id]}
                            onChange={(value) => {
                                setValues({...values, [component.id]: value});
                            }}
                        />
                    </Stack>
                </Box>
            );
        case 'money':
            return <TextField 
                label={component.name}
                onChange={(e) => {
                    // Remove todos os caracteres não numéricos
                    let value = e.target.value.replace(/\D/g, '');
                    
                    // Converte para centavos (divide por 100 para ter as casas decimais)
                    const numericValue = Number(value) / 100;
                    
                    if (!isNaN(numericValue)) {
                        const formattedValue = new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        }).format(numericValue);
                        
                        setValues({...values, [component.id]: formattedValue});
                    } else if (value === '') {
                        setValues({...values, [component.id]: ''});
                    }
                }}
                value={(values && values[component.id]) || ''}
                required={component.isRequired}
                variant="outlined"
                fullWidth
                InputProps={{
                    inputMode: 'numeric'
                }}
            />;
        case 'address':
            return (
                <AddressComponent
                    component={component}
                    values={values}
                    setValues={setValues}
                />
            );
        default:
            return <></>;
    }
}