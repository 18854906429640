import { useRef, useEffect } from "react";
import BpmnJS from "bpmn-js";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";

import ZoomScrollModule from "diagram-js/lib/navigation/zoomscroll";
import MoveCanvasModule from "diagram-js/lib/navigation/movecanvas";

type BpmnViewerProps = {
  spec: string;
};

export function BpmnViewer({ spec }: BpmnViewerProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const viewer = new BpmnJS({
      container: containerRef.current,
      additionalModules: [ZoomScrollModule, MoveCanvasModule],
    } as any);

    viewer
      .importXML(spec)
      .then(() => {
        const canvas = viewer.get("canvas");
        canvas.zoom("fit-viewport");
      })
      .catch((err: Error) => {
        console.error("Erro ao carregar o diagrama BPMN:", err);
      });

    return () => {
      viewer.destroy();
    };
  }, [spec]);

    return <div ref={containerRef} style={{ height: '600px', width: '100%' }} />;
} 