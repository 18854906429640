import { Box, Button, CircularProgress, FormControl, FormControlLabel, IconButton, List, ListItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import axiosClient from '../../../../libs/axios';
import { DynamicFormRender } from '../../../../components/DynamicForm/DynamicFormRender';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

interface ResolveTaskFormProps {
    code: string;
    task: any;
    onClose: () => void;
}

interface FormResponse {
    [key: string]: FormResponse | string | Array<String> | File;
}

interface FormValues {
    observation?: string | null;
    form_response?: FormResponse | null;
    chosen?: string | null;
    files: File[];
}

export function ResolveTaskForm({ code, task, onClose }: ResolveTaskFormProps) {

    const methods = useForm<FormValues>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            observation: null,
            chosen: null,
            files: [],
        },
    });

    const queryClient = useQueryClient();

    const { mutate, isLoading } = useMutation((data: FormData) => axiosClient.post('/task/resolve/' + task.id, data, { headers: { 'Content-Type': 'multipart/form-data' }, }),
        {
            onSuccess: (response) => {
                enqueueSnackbar('Tarefa resolvida com sucesso', { variant: 'success' });
                queryClient.invalidateQueries(["process", code]);
                methods.setValue("observation", "");
                methods.setValue("files", []);
            },
            onError: () => {
                enqueueSnackbar('Erro ao resolver processo', { variant: 'error' });
            },
        }
    );

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        const formData = new FormData();

        if (data.observation) {
            formData.append('observation', data.observation);
        }

        if (data.chosen) {
            formData.append('chosen', data.chosen);
        }

        const processFormResponse = (obj: any, parentKey = ''): any => {
            if (obj instanceof File) {
                formData.append(`files${parentKey}`, obj);
                return `__FILE__${parentKey}`;
            } else if (Array.isArray(obj)) {
                return obj.map((item, index) => processFormResponse(item, `${parentKey}[${index}]`));
            } else if (obj && typeof obj === 'object') {
                if (obj.$d && typeof obj.toISOString === 'function') {
                    return obj.toISOString();
                }
                const processedObj: any = {};
                for (const key in obj) {
                    processedObj[key] = processFormResponse(obj[key], `${parentKey}[${key}]`);
                }
                return processedObj;
            }
            return obj;
        };

        if (data.form_response) {
            const processedResponse = processFormResponse(data.form_response);
            formData.append('form_response', JSON.stringify(processedResponse));
        }

        data.files.forEach((file: File, index: number) => {
            formData.append(`files[${index}]`, file);
        });


        mutate(formData);
    };

    const handleFileRemove = (index: number): void => {
        const updatedFiles = methods.getValues('files').filter((file: File, i: number) => i !== index);
        methods.setValue("files", updatedFiles);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            methods.setValue("files", Array.from(event.target.files));
        }
    };


    return (
        <FormProvider {...methods}>
            {
                task.type == 'Conditional' && (
                    <Box mb={2}>
                        <Typography variant="caption" fontWeight="bold">
                            Escolha uma opção *
                        </Typography>
                        <Controller
                            name="chosen"
                            control={methods.control}
                            rules={{ required: 'Campo obrigatório' }}
                            render={({ field }) => (
                                <RadioGroup
                                    {...field}
                                    row
                                    name="row-radio-buttons-group"
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        field.onChange((event.target as HTMLInputElement).value);
                                    }}
                                >
                                    {task.options.map((i: string) => <FormControlLabel value={i} control={<Radio />} label={i} />)}
                                </RadioGroup>
                            )}
                        />
                    </Box>
                )
            }

            {
                task.form &&
                <Controller
                    name="form_response"
                    control={methods.control}
                    rules={{ validate: (value) => { 
                        if(task.form.find((i: any) => i.isRequired) == undefined) return true;
                        return task.form.find((i: any) => i.isRequired && !value?.hasOwnProperty(i.id)) == undefined;
                    }}}
                    render={({ field }) => (
                        <DynamicFormRender
                            components={task.form}
                            defaultValues={task.form_response}
                            onChange={(values: any) => {
                                field.onChange(values);
                            }}
                        />
                    )}
                />
            }

            <FormControl fullWidth variant="outlined" required>
                <Controller
                    name="observation"
                    control={methods.control}
                    rules={{ required: task.required_observation ? 'Campo obrigatório' : false }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Observações"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            required={task.required_observation}
                        />
                    )}
                />
            </FormControl>

            <List>
                {methods.watch('files').map((file: File, index: number) => (
                    <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2">{file.name}</Typography>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleFileRemove(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button 
                    variant="contained" 
                    disabled={isLoading || !methods.formState.isValid}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    {isLoading && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                    Resolver tarefa
                </Button>

                <Button
                    variant="outlined"
                    component="label"
                    startIcon={<FileUploadOutlinedIcon />}
                >
                    Anexar arquivos
                    <input
                        type="file"
                        hidden
                        multiple
                        onChange={handleFileChange}
                    />
                </Button>
            </Box>


        </FormProvider>
    );
}
