const chipStatusLabels: Record<'Done' | 'Doing' | 'Suspended', 'Finalizado' | 'Em execução' | 'Suspenso'> = {
  Done: 'Finalizado',
  Doing: 'Em execução',
  Suspended: 'Suspenso',
};

export function situationLabel(situation: string) {
  return chipStatusLabels[situation as 'Doing' || 'Done' || 'Suspended']
}

